// src/App.js
import './App.css'
import React, { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { NavLink, Outlet} from 'react-router-dom'

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Listen for changes in user authentication state
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        // User is logged in
        setUser(currentUser);
      } else {
        // User is logged out
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      
      {user ? (
        <Dashboard />
      
      ) : (
        <>
                    <div style={{  overflow: "hidden" }}>
 <div className = "header" style={{ border: "2px solid #73AD21" , borderRadius: "10%", margin: "1%"}}>

                        <div className="search-box" style={{margin: "2%", borderRadius: "5%"}}>

                           Assignment
                        </div>


<div className="HeaderIcons" >

</div>

</div>
</div>
         <Outlet/>
        </>
        

      )}
    </div>
  );
};

export default App;
