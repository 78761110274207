
import React from 'react';
import { auth } from '../firebaseConfig';
import {useNavigate} from 'react-router-dom'

const Dashboard = () => {
    const navigate = useNavigate()
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/")
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <h2>Hello World</h2>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Dashboard;
