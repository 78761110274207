
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import './Login.css'
import { NavLink} from 'react-router-dom'

 function Login() {
    
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState("password");
  const [Loader, setLoader] = useState(false);
  // const navigate = useNavigate();
  const navigate = useNavigate()
 

  const ShowPass = () => {
    if (showPass === "password") {
      setShowPass("text")
    } else {
      setShowPass("password")
    }

  }

  const handleSubmit = async (e) => {
   
         
    e.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      alert(error.message);
    }

  }


  return (

    <form onSubmit={handleSubmit} className="sign-in-form">
  <h3 className="form-title">Sign In</h3>
  <div className="form-group">
    <label className="form-label">Email address</label>
    <input
      type="email"
      className="form-control"
      placeholder="Enter email"
      name="email"
      onChange={(e) => setEmail(e.target.value)}
    />
  </div>
  <div className="form-group">
    <label className="form-label">Password</label>
    <input
      type={showPass}
      className="form-control"
      placeholder="Enter password"
      name="pass"
      id="pass"
      onChange={(e) => setPassword(e.target.value)}
    />
    <input type="checkbox" onClick={ShowPass} className="show-password" /> Show password
  </div>
  <div className="form-group">
   
  </div>
  <div className="form-group">
    <button type="submit" className="btn btn-primary form-btn">
      <span>Submit</span>
      <i className="ri-check-line icon"></i>
    </button>
  </div>
  <p className="forgot-password text-right">
   Don't have any account ? <NavLink exact to="/signUp"  className="forgot-link"> Sign Up </NavLink>
  </p>
</form>

  )

}


export default Login;