import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import App from './App';
import { BrowserRouter as Router, Routes, Route, useRoutes } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    
  <Routes> 
    <Route path="/" element={<App />}>
    <Route index element ={<Login/>}/>

      <Route path = '/signUp' element ={<SignUp/>}/>
      </Route>
  
      
    </Routes>

</Router>
);


