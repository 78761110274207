// src/components/SignUp.js

import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { NavLink} from 'react-router-dom'

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState("password");

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      await auth.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      alert(error.message);
    }
  };
  const ShowPass = () => {
    if (showPass === "password") {
      setShowPass("text")
    } else {
      setShowPass("password")
    }

  }
  return (
    <form onSubmit={handleSignUp} className="sign-in-form">
    <h3 className="form-title">Sign Up</h3>
    <div className="form-group">
      <label className="form-label">Email address</label>
      <input
        type="email"
        className="form-control"
        placeholder="Enter email"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
    <div className="form-group">
      <label className="form-label">Password</label>
      <input
        type={showPass}
        className="form-control"
        placeholder="Enter password"
        name="pass"
        id="pass"
        onChange={(e) => setPassword(e.target.value)}
      />
      <input type="checkbox" onClick={ShowPass} className="show-password" /> Show password
    </div>
    <div className="form-group">
     
    </div>
    <div className="form-group">
      <button type="submit" className="btn btn-primary form-btn">
        <span>Submit</span>
        <i className="ri-check-line icon"></i>
      </button>
    </div>
    <p className="forgot-password text-right">
     Already have an account ? <NavLink exact to="/"  className="forgot-link"> Sign In </NavLink>
    </p>
  </form>
  );
};

export default SignUp;
