// src/firebaseConfig.js

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCx4JB6TRU3XKND-QwQcWsvBmy3g7lITTA",
    authDomain: "assignmentbreeze.firebaseapp.com",
    projectId: "assignmentbreeze",
    storageBucket: "assignmentbreeze.appspot.com",
    messagingSenderId: "689899646706",
    appId: "1:689899646706:web:8a40fbdd1935b5e1432a6b"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export the auth service
export const auth = firebase.auth();
